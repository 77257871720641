import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  values, get, debounce,
} from 'lodash'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'

import * as api from 'api'
import { translate } from 'i18n'
import { isReferenceCountPremiumRestricted } from 'selectors/subscriptions'
import { showModal } from 'actions/modal'
import ReferenceSelector, { ReferenceLibraries, EXTERNAL_LIBRARIES } from 'selectors/references'
import { PAYWALL_MODAL_ID } from 'containers/modal/PaywallModal'

import ReferenceCitation from 'components/common/ReferenceCitation'
import AutoSuggestButton from 'components/common/AutoSuggestButton'
import { PlainLibrarySelector } from 'containers/references/LibrarySelector'
import Spinner from 'components/common/Spinner'

import './style.less'

const NO_QUERY_SUGGESTION_COUNT = 3

class ReferencePicker extends Component {
  static propTypes = {
    citationCluster: PropTypes.shape({}),
    onSelect: PropTypes.func,
    t: PropTypes.func,
    references: PropTypes.shape({}),
    onCreate: PropTypes.func,
    ignoreIds: PropTypes.arrayOf(PropTypes.string),
    hintTranslationKey: PropTypes.string,
    displayAutoSuggest: PropTypes.bool,
    className: PropTypes.string,
    disableCreate: PropTypes.bool,
    showModal: PropTypes.func,
    isPremiumRestricted: PropTypes.bool,
  }

  constructor() {
    super()
    this.debouncedFetchExternalReferences = debounce(this.fetchExternalReferences, 1000)
    this.setSearchState = this.setStateItem('searchState')
    this.getSearchState = this.getStateItem('searchState')
    this.setSearchResult = this.setStateItem('searchResult')
    this.getSearchResult = this.getStateItem('searchResult')
  }

  state = {
    userSuggestions: [],
    selectedLibrary: ReferenceLibraries.PERSONAL,
    isLoading: false,
  }

  setStateItem = prefix => (libKey, value) => this.setState({ [`${prefix}_${libKey}`]: value })

  getStateItem = prefix => libKey => get(this.state, [`${prefix}_${libKey}`])

  getUserSuggestions = (queryText = '', reason) => {
    const { ignoreIds, citationCluster, references } = this.props
    let suggestions = null
    const inlineRefIds = get(citationCluster, 'bibliography.meta.referenceIds', [])
    const notLinked = r => !ignoreIds.includes(r.id) && !inlineRefIds.includes(r.id)
    if (get(queryText, 'length', 0) > 0 || reason === 'suggestion-selected') {
      suggestions = values(references)
        .filter(r => notLinked(r)
          && r.citation
          && r.citation.toLowerCase().indexOf(queryText.toLowerCase()) >= 0)
        .slice(0, 5)
    } else {
      suggestions = values(this.props.references)
        .filter(notLinked)
        .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
        .slice(0, NO_QUERY_SUGGESTION_COUNT)
    }
    this.setState({ userSuggestions: suggestions })
    this.setSearchState(ReferenceLibraries.PERSONAL, get(suggestions, 'length', 'SUCCESS'))
  }

  getExternalSuggestions = async (libKey, queryText) => {
    if (!queryText) {
      this.setSearchResult(libKey, {})
      return
    }
    let payload
    try {
      this.setSearchState(libKey, 'LOADING')
      payload = await api.searchExternalReferences(libKey, queryText)
      this.setSearchState(libKey, payload.totalCount || 'SUCCESS')
      this.setSearchResult(libKey, payload)
    } catch (err) {
      this.setSearchState(libKey, 'ERROR')
    }
  }

  fetchExternalReferences = (queryText) => {
    Promise.all(EXTERNAL_LIBRARIES.map(libKey => this.getExternalSuggestions(libKey, queryText)))
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    this.getUserSuggestions(value, reason)
    this.debouncedFetchExternalReferences(value)
  }

  onSuggestionsClearRequested = () => {
    this.onSuggestionsFetchRequested({ value: '' })
  }

  getSuggestions = () => {
    const { selectedLibrary } = this.state
    const { ignoreIds } = this.props
    switch (selectedLibrary) {
      case ReferenceLibraries.PERSONAL:
        return this.state.userSuggestions
      default: {
        const notLinked = r => !ignoreIds.includes(r.id) && !ignoreIds.includes(r.externalId)
        return values(get(this.getSearchResult(selectedLibrary), ['entities', 'externalReference'])).filter(notLinked)
      }
    }
  }

  getSearchStates = () => {
    const states = {}
    EXTERNAL_LIBRARIES.forEach((libKey) => { states[libKey] = this.getSearchState(libKey) })
    return states
  }

  getSuggestionValue = reference => reference.title

  handleTabSelect = value => this.setState({ selectedLibrary: value })

  handleSuggestionSelect = async (suggestion) => {
    const { onSelect, isPremiumRestricted } = this.props
    console.log('suuuug', suggestion)
    if (isPremiumRestricted && suggestion.fromLibrary) {
      this.props.showModal(PAYWALL_MODAL_ID)
      return
    }
    this.setState({ isLoading: true })
    await onSelect(suggestion)
    this.setState({ isLoading: false })
  }

  renderHeader = ({ query }) => (
    <React.Fragment>
      <PlainLibrarySelector
        className="ReferencesPage__LibrarySelector"
        userLibReferenceCount={query && query.length > 0 ? get(this.state.userSuggestions, 'length') : Math.min(NO_QUERY_SUGGESTION_COUNT, get(values(this.props.references), 'length'))}
        selectedValue={this.state.selectedLibrary}
        onSelect={this.handleTabSelect}
        libraryStates={this.getSearchStates()}
        showIndicator={query && query.length > 0}
        useLogo
        autoGrow
      />
      {(!query && this.state.selectedLibrary === ReferenceLibraries.PERSONAL && get(this.getSuggestions(), 'length') > 0 ? <div className="react-autosuggest__header">{this.props.t('common.recentlyUpdated')}</div> : null)}
    </React.Fragment>
  )

  renderMyReferenceSuggestion = reference => <div><ReferenceCitation className="ReferencePicker__Reference--suggestList" reference={reference} /></div>

  renderAuthorString = reference => (reference.author && reference.author.filter(a => !!a).map(a => a.literal || `${a.given || ''} ${a.family || ''}`).join(', ')) || (reference.editor && reference.editor.filter(a => !!a).map(a => a.literal || `${a.given || ''} ${a.family || ''}`).join(', '))

  renderExternalReferenceSuggestion = reference => (
    <div className="ReferencePicker__ExternalReference">
      <div className="ReferencePicker__ExternalReferenceTitle">
        {reference.title}
      </div>
      <div className="ReferencePicker__ExternalReferenceDetails">
        <div className="ReferencePicker__ExternalReferenceAuthors">
          {this.renderAuthorString(reference)}
        </div>
        <div>{this.props.t(`references:types.${reference.type || 'article'}.name`)}</div>
        <div className="ReferencePicker__ExternalReferenceDate">{get(reference, 'issued.raw')}</div>
      </div>
    </div>
  )

  renderSuggestion = reference => (reference && reference.citation
    ? this.renderMyReferenceSuggestion(reference)
    : this.renderExternalReferenceSuggestion(reference))

  render = () => {
    const {
      onCreate, displayAutoSuggest, className, disableCreate,
    } = this.props
    return (
      <div className={`ReferencePicker ${className}`}>
        <AutoSuggestButton
          title={this.props.t('noteDetailView.referenceSelectorField.linkReference')}
          suggestions={this.getSuggestions()}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderHeader={this.renderHeader}
          createLabel={!disableCreate && this.props.t('noteDetailView.referenceSelectorField.createReference')}
          helpSpotTranslationKey="noteDetailView.referenceSelectorField.helpSpot"
          formValue={this.props.ignoreIds}
          hintTranslationKey={this.props.hintTranslationKey}
          onCreateSelected={onCreate}
          onSuggestionSelected={this.handleSuggestionSelect}
          displayAutoSuggest={displayAutoSuggest}
        />
        { this.state.isLoading && <div className="ReferencePicker__LoadingOverlay"><Spinner show /></div>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  references: ReferenceSelector.getRaw(state),
  isPremiumRestricted: isReferenceCountPremiumRestricted(state),
})

export default connect(mapStateToProps, {
  push,
  showModal,
})(translate()(ReferencePicker))
