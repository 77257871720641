import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  reduxForm,
  Field,
  getFormValues,
  initialize,
  submit,
} from 'redux-form'
import { isEqual } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import FontIcon from 'components/common/FontIcon'
import TagSelectorField from 'containers/note-detail/TagSelectorField'
import ReferenceSelectorField from 'containers/note-detail/ReferenceSelectorField'
import NoteSelectorField from 'containers/note-detail/NoteSelectorField'
import ColorSelectorField from 'components/common/ColorSelectorField'

import Textarea from 'components/TextareaAutosize'
import Editor from 'components/note-detail/Editor'
import HelpSpot from 'containers/HelpSpot'
import { StickyContainer } from 'react-sticky'

import Icon from 'components/common/Icon'
import { PremiumOutlinedButton, OutlinedButtonTooltip } from 'components/common/Button'
import { AuratikumFontIcons } from 'helper/utils'
import FeatureFlag from 'containers/FeatureFlag'
import SnapSplitPane from 'components/common/SnapSplitPane'
import NoteFilesField from 'containers/note-detail/NoteFilesField'
import NoteLinkFlyout from 'containers/note-link/NoteLinkFlyout'
import DuplicateButton from 'components/common/DuplicateButton'
import { CitationStyleType } from 'helper/citation'
import Footnote from 'components/common/Footnote'

import './style.less'

export const FORM_NAME = 'NoteDetailForm'

const directSubmitFields = ['color', 'tags', 'followingNotes', 'linkedNotes', 'referenceIds', 'files']
const debouncedSubmitFields = ['title', 'content']

// this specific thunk action is necessary to get the current note detail form values
const reinitializeNoteForm = result => async (dispatch, getState) => {
  // result is the response note object coming from the server
  const fields = [...directSubmitFields, ...debouncedSubmitFields]
  const formValues = getFormValues(FORM_NAME)(getState())
  if (!formValues) {
    // if form is closed while saving, formValues will be undefined
    return
  }

  let changed = false

  fields.forEach((fieldName) => {
    if (JSON.stringify(result[fieldName] || '') !== JSON.stringify(formValues[fieldName] || '')) {
      changed = true
    }
  })
  if (changed) {
    dispatch(submit(FORM_NAME))
  } else {
    dispatch(initialize(FORM_NAME, result))
  }
}


const formOnChange = (values, dispatch, props, previousValues) => {
  if (values.id === previousValues.id) {
    // if note didn't change, autosave form
    // debounce text fields
    debouncedSubmitFields.forEach((fieldName) => {
      if (JSON.stringify(values[fieldName]) !== JSON.stringify(previousValues[fieldName])) {
        props.debouncedSubmit()
      }
    })

    // update citation cluster if citations changed
    // console.log(values.inlineCitations, isEqual(values.inlineCitations, previousValues.inlineCitations))
    if (!isEqual(values.inlineCitations, previousValues.inlineCitations) || !isEqual(values.referenceIds, previousValues.referenceIds)) {
      props.updateNoteCitationCluster(values)
    }

    // directly save other fields
    let shouldSubmit = false
    directSubmitFields.forEach((fieldName) => {
      if (JSON.stringify(values[fieldName]) !== JSON.stringify(previousValues[fieldName])) {
        shouldSubmit = true
      }
    })
    if (shouldSubmit) {
      if (!props.submitting) {
        props.submit()
      }
    }
  }
}

const formOnSubmitSuccess = (result, dispatch) => {
  dispatch(reinitializeNoteForm(result))
}

class NoteDetailForm extends Component {
  state = {
    showNoteLinkFlyout: false,
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.note && prevProps.formValues && this.props.note.id !== prevProps.formValues.id) {
      this.props.dispatch(initialize(FORM_NAME, this.props.note))
    }
  }

  handleDeleteNote = () => {
    const { formValues, onDeleteNote } = this.props
    onDeleteNote(formValues)
  }

  handleLinkNote = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { showNoteLinkFlyout } = this.state
    this.setState({
      showNoteLinkFlyout: !showNoteLinkFlyout,
    })
  }

  handleLinkNoteFlyoutOutsideClick = () => {
    this.setState({
      showNoteLinkFlyout: false,
    })
  }

  handleDuplicateNote = async () => {
    if (!this.props.isPremiumRestricted) {
      const { formValues, onDuplicateNote } = this.props
      await onDuplicateNote(formValues)
    }
  }

  handleTitleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  handleReferenceChange = () => {
    this.props.updateNoteCitationCluster(this.props.formValues)
  }

  renderProjectLink = project => (
    <NavLink className="NoteContent__ProjectLink" to={`/projects/${project.id}`}>
      {project.title}
    </NavLink>
  )

  renderMetaInfos = () => {
    const { formValues, t } = this.props
    const metaInfos = [
      { title: t('noteDetailView.metaInfo.createdAt'), value: t('common.dateTimeLong', { date: moment(formValues.createdAt) }) },
      { title: t('noteDetailView.metaInfo.updatedAt'), value: t('common.dateTimeLong', { date: moment(formValues.updatedAt) }) },
    ]
    if (this.props.createdInProject) {
      metaInfos.push({
        title: t('noteDetailView.metaInfo.createdInProject'),
        value: this.renderProjectLink(this.props.createdInProject),
      })
    }

    if (this.props.usedInProjects && this.props.usedInProjects.length > 0) {
      metaInfos.push({
        title: t('noteDetailView.metaInfo.usedIdProjects'),
        value: this.props.usedInProjects.map((project, i) => (
          <React.Fragment key={project.id}>
            {!!i && ', '}
            {this.renderProjectLink(project)}
          </React.Fragment>
        )),
      })
    }
    return metaInfos.map(metaInfo => (
      <div key={metaInfo.title} className="NoteContent__Meta">
        <div className="NoteContent__MetaTitle">{metaInfo.title}</div>
        <div className="NoteContent__MetaValue">{metaInfo.value}</div>
      </div>
    ))
  }

  renderFootnotes = () => {
    const { citationCluster } = this.props
    if (citationCluster.type !== CitationStyleType.FOOTNOTE) return null

    return (
      <div className="NoteDetailForm__Footnotes">
        {citationCluster.citations.map(citation => (<Footnote key={citation.citationId} citation={citation} />))}
      </div>
    )
  }


  renderActionToolbar = () => (
    <div className="ActionToolbar">
      <FeatureFlag feature="service-platform">
        <div className="ProjectDetailPage__HeaderButton">
          <PremiumOutlinedButton circleModifier="circleMedium" onClick={this.props.onServiceClick}>
            <FontIcon icon={FontIcon.Icons.faLifeRing} size="2x" />
          </PremiumOutlinedButton>
        </div>
      </FeatureFlag>
      <NoteLinkFlyout
        showFlyout={this.state.showNoteLinkFlyout}
        onOutsideClick={this.handleLinkNoteFlyoutOutsideClick}
        note={this.props.formValues}
      >
        <OutlinedButtonTooltip
          className="ActionToolbar__Button"
          onClick={this.handleLinkNote}
          circleModifier="circleMedium"
          tooltipText={this.props.t('linkNoteFlyout.addToOutline')}
          tooltipPlacement="bottom"
        >
          <Icon className="ActionToolbar__BoardAddIcon" icon={AuratikumFontIcons.BOARDADD} />
        </OutlinedButtonTooltip>
      </NoteLinkFlyout>
      <DuplicateButton
        className="ActionToolbar__Button"
        onClick={this.handleDuplicateNote}
        circleModifier="circleMedium"
        tooltipText={this.props.t('notes.duplicate')}
        tooltipPlacement="bottom"
        enablePaywall={this.props.isPremiumRestricted}
      />
      <OutlinedButtonTooltip
        className="ActionToolbar__Button"
        onClick={this.handleDeleteNote}
        circleModifier="circleMedium"
        tooltipText={this.props.t('notes.delete')}
        tooltipPlacement="bottom"
      >
        <Icon icon={AuratikumFontIcons.DELETE} />
      </OutlinedButtonTooltip>
    </div>
  )

  renderLeftColumn() {
    const {
      initialValues, formValues, onSaveEditor, citationCluster,
    } = this.props

    return (
      <React.Fragment>
        <StickyContainer className="NoteDetailForm__Column">
          { formValues.isInitial && (
            <div className="NoteDetailForm__InitialNoteHint">
              {this.props.t('noteDetailView.sampleNoteDisclaimer')}
            </div>
          )}
          <div>
            <Field
              onKeyDown={this.handleTitleKeyPress}
              name="title"
              component={Textarea}
              className="NoteDetailForm__Heading"
              placeholder="Titel"
            />
            <HelpSpot
              className="NoteDetailForm__HelpSpotTitle"
              translationKey="helpSpot.noteDetailViewNote"
            />
          </div>
          <div>
            <Field
              name="content"
              component={Editor}
              noteId={initialValues.id}
              onSave={onSaveEditor}
              formContext={this}
              className="NoteDetailForm__Content"
              citationCluster={citationCluster}
            />
            <HelpSpot
              className="NoteDetailForm__HelpSpotContent"
              translationKey="helpSpot.noteDetailViewSave"
            />
          </div>
        </StickyContainer>
        {this.renderFootnotes()}
      </React.Fragment>
    )
  }

  renderRightColumn = () => (
    <div className="NoteDetailForm__Column NoteDetailForm__Column--right">
      <Field
        name="files"
        component={NoteFilesField}
        className="NoteDetailForm__Files"
        formValues={this.props.formValues}
      />
      <Field name="tags" component={TagSelectorField} type="text" className="NoteDetailForm__Tags" />
      <Field
        name="referenceIds"
        component={ReferenceSelectorField}
        note={this.props.initialValues}
        formIsDirty={this.props.dirty}
        onChange={this.handleReferenceChange}
        citationCluster={this.props.citationCluster}
      />
      <Field
        name="linkedNotes"
        component={NoteSelectorField}
        exclude={[this.props.initialValues.id]}
        className="NoteDetailForm__Content"
        linkedNote={this.props.initialValues}
      />
      <Field name="color" component={ColorSelectorField} className="NoteDetailForm__Content" />
      {this.renderMetaInfos()}
    </div>
  )

  render() {
    const { handleSubmit } = this.props
    return (
      <div className="NoteDetailForm">
        <form className="NoteDetailForm__Form" onSubmit={handleSubmit}>
          <SnapSplitPane
            defaultSize="50%"
            primary="second"
            pane1ClassName="NoteDetailForm__SplitPane NoteDetailForm__SplitPane--column"
            pane2ClassName="NoteDetailForm__SplitPane"
          >
            {this.renderLeftColumn()}
            {this.renderRightColumn()}
          </SnapSplitPane>
        </form>
        {this.renderActionToolbar()}
      </div>
    )
  }
}


const form = {
  form: FORM_NAME,
  onChange: formOnChange,
  onSubmitSuccess: formOnSubmitSuccess,
}

NoteDetailForm.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSaveEditor: PropTypes.func,
  onDeleteNote: PropTypes.func,
  onDuplicateNote: PropTypes.func,
  formValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  note: PropTypes.shape({}),
  createdInProject: PropTypes.shape({}),
  usedInProjects: PropTypes.arrayOf(PropTypes.shape({})),
  onServiceClick: PropTypes.func.isRequired,
  isPremiumRestricted: PropTypes.bool,
  updateNoteCitationCluster: PropTypes.func,
  ...i18nPropTypes,
}

export default reduxForm(form)(translate()(NoteDetailForm))
