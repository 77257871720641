import React, { Component } from 'react'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import PropTypes from 'prop-types'

import FlyoutReference from 'containers/common/FlyoutReference'
import FlyoutHeader from 'components/common/FlyoutHeader'
import FlyoutOutlineContainer from 'containers/note-link/FlyoutOutlineContainer'

import './style.less'


class NoteLinkFlyout extends Component {
  static propTypes = {
    className: PropTypes.string,
    showFlyout: PropTypes.bool,
    onOutsideClick: PropTypes.func,
    note: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  handleHideFlyout = () => this.props.onOutsideClick()

  renderFlyoutContent = () => {
    const { t, note } = this.props
    return (
      <React.Fragment>
        <FlyoutHeader title={t('linkNoteFlyout.addToOutline')} />
        <FlyoutOutlineContainer note={note} onNoteLinked={this.handleHideFlyout} />
      </React.Fragment>
    )
  }

  render() {
    const { className, showFlyout } = this.props
    return (
      <FlyoutReference
        flyoutClassName={`NoteLinkFlyout ${className}`}
        showFlyout={showFlyout}
        placement="bottom"
        component={this.renderFlyoutContent()}
        onOutsideClick={this.handleHideFlyout}
      >
        { this.props.children }
      </FlyoutReference>
    )
  }
}

export default translate()(NoteLinkFlyout)
