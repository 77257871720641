import _ from 'lodash'
import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import { hasInitialNotes } from 'selectors/notes'
import { hasInitialReferences } from 'selectors/references'
import { hasInitialProjects } from 'selectors/projects'
import { cognitoGetUser } from 'integrations/AWSCognito'
import { profileSchema } from 'api/schema'
import { WEBAPP_ROOT_URL } from 'helper/constants'

export const selector = state => state.entities.profiles
export const subscriptionSelector = state => state.entities.subscriptions || {}

export const getProfile = createSelector(
  selector,
  subscriptionSelector,
  (profiles, subscriptions) => {
    const profile = _.values(profiles)[0]
    if (profile) {
      return denormalize(profile, profileSchema, { subscriptions })
    }
    return null
  },
)

export const getProfileLanguage = createSelector(
  getProfile,
  profile => _.get(profile, 'language'),
)

const getMaxNoteCount = createSelector(
  getProfile,
  profile => Math.max(_.get(profile, 'maxNoteCount', 0), 20),
)

const getMaxProjectCount = createSelector(
  getProfile,
  profile => Math.max(_.get(profile, 'maxProjectCount', 0), 1),
)

const getMaxReferenceCount = createSelector(
  getProfile,
  profile => Math.max(_.get(profile, 'maxReferenceCount', 0), 20),
)

const getInviteLink = createSelector(
  getProfile,
  profile => `${WEBAPP_ROOT_URL}/register?token=${_.get(profile, 'id')}`,
)

const getUserName = createSelector(
  getProfile,
  profile => ((profile.firstName || profile.lastName) ? `${profile.firstName || ''} ${profile.lastName || ''}`.trim() : cognitoGetUser().username),
)

const getReferrals = createSelector(
  getProfile,
  profile => profile.referrals || [],
)

const hasInitialData = createSelector(
  hasInitialNotes,
  hasInitialReferences,
  hasInitialProjects,
  (hasNotes, hasReferences, hasProjects) => hasNotes || hasReferences || hasProjects,
)

export default {
  getProfile,
  getUserName,
  getMaxNoteCount,
  getMaxProjectCount,
  getMaxReferenceCount,
  getInviteLink,
  getReferrals,
  hasInitialData,
}
