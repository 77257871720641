import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fieldPropTypes } from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'
import {
  isNoColor,
  noteColors,
} from 'helper/colorHandler'
import ProfileSelector from 'selectors/profile'
import FontIcon from 'components/common/FontIcon'
import FlyoutReference from 'containers/common/FlyoutReference'
import FlyoutHeader from 'components/common/FlyoutHeader'
import ColorNamingForm from 'components/note-detail/ColorNamingForm'
import { SimpleButtonToolTip } from 'components/common/Button'
import ColorButton from 'components/common/ColorButton'

import './style.less'


class ColorSelectorField extends Component {
  static propTypes = {
    colorLabels: PropTypes.shape({}),
    colors: PropTypes.arrayOf(PropTypes.string),
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      showColorNamingFlyout: false,
    }
    this.colorStrings = this.props.colors.map(color => color.join(','))
  }

  handleShowColorNamingFlyout = () => {
    this.setState(state => ({ showColorNamingFlyout: !state.showColorNamingFlyout }))
  }

  handleHideFlyout = () => this.setState({ showColorNamingFlyout: false })


  renderFlyoutContent = () => {
    const { t } = this.props
    return (
      <React.Fragment>
        <FlyoutHeader title={t('noteDetailView.colorNaming')} />
        <ColorNamingForm />
      </React.Fragment>
    )
  }

  renderColorButton = color => (
    <ColorButton
      key={color}
      color={color}
      value={this.props.input.value}
      onChange={this.props.input.onChange}
      tooltipText={this.props.colorLabels[color] || this.props.t('noteDetailView.unnamedColor')}
      tooltipPlacement="bottom"
    />
  )

  render() {
    const { t } = this.props
    const isCustomColor = this.colorStrings.indexOf(this.props.input.value) < 0 && !isNoColor(this.props.input.value)
    return (
      <div className={modifyClassName('ColorSelectorField')}>
        { isCustomColor && this.renderColorButton(this.props.input.value) }
        { this.colorStrings.map(color => this.renderColorButton(color)) }
        { this.renderColorButton() }
        <FlyoutReference
          flyoutClassName="ColorSelectorField__ColorNamingFlyout"
          showFlyout={this.state.showColorNamingFlyout}
          placement="bottom"
          component={this.renderFlyoutContent()}
          onOutsideClick={this.handleHideFlyout}
        >
          <SimpleButtonToolTip
            className="ColorSelectorField__EditButton"
            onClick={this.handleShowColorNamingFlyout}
            tooltipText={t('noteDetailView.colorNaming')}
            tooltipPlacement="bottom"
          >
            <FontIcon icon={FontIcon.Icons.faPencil} />
          </SimpleButtonToolTip>
        </FlyoutReference>
      </div>
    )
  }
}

ColorSelectorField.defaultProps = {
  colors: noteColors,
}

ColorSelectorField.propTypes = {
  ...fieldPropTypes,
}

const mapStateToProps = state => ({
  colorLabels: ProfileSelector.getProfile(state).colorLabels || {},
})

export default connect(mapStateToProps, {})(translate()(ColorSelectorField))
